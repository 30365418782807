import React from 'react';
import ReactDOM from 'react-dom';

import CrosswordBuilder from './2020/crossword_builder';

// make it possible to add PB to window.
declare global {
  interface Window { PB: PB }
}

/*
  Crappy catch-all class to hold everything else that doesn't go neatly into something.
  Also in charge of holding our Phaser game instance
 */
class PB {
  init() {
    console.log(`%c

     _|_|_|                                  _|            _|_|_|
     _|    _|  _|    _|  _|_|_|_|  _|_|_|_|  _|    _|_|    _|    _|    _|_|_|  _|_|_|      _|_|_|
     _|_|_|    _|    _|      _|        _|    _|  _|_|_|_|  _|_|_|    _|    _|  _|    _|  _|    _|
     _|        _|    _|    _|        _|      _|  _|        _|    _|  _|    _|  _|    _|  _|    _|
     _|          _|_|_|  _|_|_|_|  _|_|_|_|  _|    _|_|_|  _|_|_|      _|_|_|  _|    _|    _|_|_|
                                                                                               _|
                                                                                           _|_|    `, "font-family:monospace");

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    });
  }

  logout() {
    $.ajax({
      type: "DELETE",
      url: "/logout_ajax",
      dataType: "json",
      success: (data, textStatus, jqXHR) => {
        console.log("Logged out");
        window.location.href = "/login";
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.error("Could not log out?", jqXHR, textStatus, errorThrown);
      }
    });
  }

  // shots
  buildCrossword(crossword, startWith) {
    const reactRoot = document.getElementById("react-crossword");
    ReactDOM.render(<CrosswordBuilder crossword={crossword} startWith={startWith}/>, reactRoot);
  }

  // Music
  audioStopping: boolean;

  initSortableMusic = (e) => {
    // @ts-ignore
    $(".draggable").draggable({
      "revert": "invalid",
      stop: function(e, ui) {
        return $(e.target).css({
          "top": "0px",
          "left": "0px"
        });
      }
    });

    // @ts-ignore
    $(".draggable").droppable({
      hoverClass: "drop_hover",
      drop: function(e, ui) {
        const $a = $(e.target);
        const $b = $(ui.draggable);
        const $aLocation = $("<div id='_a_location'>").css("display", "none");
        const $bLocation = $("<div id='_b_location'>").css("display", "none");
        $a.before($aLocation);
        $b.before($bLocation);
        $aLocation.after($b);
        $bLocation.after($a);
        $aLocation.remove();
        return $bLocation.remove();
      }
    });

    // @ts-ignore
    $(".music").sortable({
      axis: "y",
      containment: "parent",
      connectWith: ".sortable",
      cursor: "move",
      items: "> .sortable"
    });
  }

  playRow = (e) => {
    const $row = $(e.target).parents(".row");
    $row.find("a.playpause:first").click();
    return $row.find("audio").on("ended", this.playNextAudio);
  };

  playThisAudio = (e) => {
    const $block = $(e.target).parent();
    const alreadyPlaying = $block.hasClass("playing");
    $("audio").off("ended");
    this.audioStopping = true;
    $("audio").each((i, e: HTMLAudioElement) => {
      e.load();
      e.pause();
      if (e.readyState !== 0) {
        e.currentTime == 0;
      }
    });
    $(".block").removeClass("playing");
    if (!alreadyPlaying) {
      $block.addClass("playing");
      $block.children("audio")[0].play();
      $block.children("audio").one("pause", function(e) {
        return $(e.target).parent().removeClass("playing");
      });
      return this.audioStopping = false;
    }
  }

  playNextAudio = (e) => {
    let $nextBlock, $thisBlock;
    if (!this.audioStopping) {
      $thisBlock = $(e.target).parent();
      $thisBlock.removeClass("playing");
      $nextBlock = $thisBlock.next();
      if ($nextBlock.length !== 0) {
        $nextBlock.children("audio")[0].play();
        return $nextBlock.addClass("playing");
      }
    }
  }
}

if (!window.PB){
  window.PB = new PB;
}

export default window.PB;

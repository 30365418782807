import React from 'react';

type CrosswordBuilderProp = {
  crossword: number[][],
  startWith: {[key: string]: string},
};

type CrosswordBuilderState = {
  numberToLetter: {[key: string]: string}
}

const LocalStorageShotDataKey = "PuzzleBang2020-shot-data";

export default class CrosswordBuilder extends React.Component<CrosswordBuilderProp, CrosswordBuilderState> {
  constructor(props: CrosswordBuilderProp) {
    super(props);

    this.state = {
      numberToLetter: this.getLocalStorageData(),
    };
  }

  getLocalStorageData(): {[key: string]: string} {
    let numberToLetter = {};
    if (window.localStorage) {
      const rawData = window.localStorage.getItem(LocalStorageShotDataKey);
      try {
        numberToLetter = JSON.parse(rawData);
      } catch (e) {
        console.log("Couldn't load local storage, data was", rawData);
        window.localStorage.getItem(LocalStorageShotDataKey);
      }
    }
    return {
      ...numberToLetter,
      ...this.props.startWith,
    };
  }

  updateLocalStorageData() {
    if (window.localStorage) {
      window.localStorage.setItem(LocalStorageShotDataKey, JSON.stringify(this.state.numberToLetter));
    }
  }

  handleCellChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.trim();
    if (value.length > 1) {
      value = value.substr(value.length - 1, 1);
    }
    const numberToLetter = {
        ...this.state.numberToLetter,
        [e.target.name]: value,
        ...this.props.startWith,
      };
    if (value.length == 0) {
      delete numberToLetter[e.target.name];
    }
    this.setState({
      numberToLetter,
    }, () => {
      this.updateLocalStorageData();
    });
  }

  handleReset = () => {
    this.setState({
      numberToLetter: this.props.startWith,
    }, () => {
      this.updateLocalStorageData();
    });
  }

  render() {
    const crossword = this.props.crossword;

    return (
      <>
        <table className="crossword-shot">
          <tbody>
          {
            crossword.map((row, index) => {
              const rowContents = row.map((cell, index) => {
                return (
                  <td key={`col-${index}`}>
                    {
                      cell !== 0 &&
                      <>
                        <div className="crossword-number">{cell}</div>
                        <input
                          className="crossword-input"
                          name={cell.toString()}
                          value={this.state.numberToLetter[cell] || " "}
                          onChange={this.handleCellChange}
                        />
                      </>
                    }
                  </td>
                );
              });
              return <tr key={`row-${index}`}>{rowContents}</tr>;
            })
          }
          </tbody>
        </table>
        <div className="crossword-shot-clear">
          <a onClick={this.handleReset}>Reset</a>
        </div>
      </>
    );
  }
}
